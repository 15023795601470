import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Icons from 'features/shared/components/icons';
import { useIntl } from '@domains/i18n';
import { Link } from '@features/shared/components/link';
import { IMenuItem } from '@features/shared/contentful/types/IMenuItem';
import useBreakpoints from '@utils/hooks/use-breakpoints';

import { Button, H3 } from 'src/features/shared/components/typography';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import styles from './LinkColumn.module.scss';

export interface ILinkColumnProps {
  links: IMenuItem[];
  subtitle?: string;
  isImageBanner?: boolean;
}

export const LinkColumn = ({
  subtitle,
  links,
  isImageBanner = false,
}: ILinkColumnProps) => {
  const { formatMessage } = useIntl();
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const [list, setList] = useState(isMobile ? links.slice(0, 5) : links);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    setList(isCollapsed && isMobile ? links.slice(0, 5) : links);
  }, [isCollapsed, isMobile]);

  return (
    <>
      {subtitle && <H3 className={styles.subtitle}>{subtitle}</H3>}

      <div className={styles.linkColumn}>
        {list.map((link) => (
          <Link
            className={classNames(styles.link, {
              [styles.fullWidth]: !isImageBanner,
            })}
            key={link.link?.label}
            href={link.link?.href || ''}
            openInNewTab={link.link?.openInNewTab}
            onClick={() => {
              pushGA4Event(GA4_EVENTS.trackEvent, {
                event_name: 'click_item_linklist',
              });
            }}
          >
            {link.link?.label}
          </Link>
        ))}
        {links.length > 5 && isMobile && (
          <a
            role="button"
            className={styles.collapseButton}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? (
              <Icons.Plus className={styles.plusIcon} />
            ) : (
              <Icons.Minus className={styles.plusIcon} />
            )}
            <Button>
              {formatMessage(
                `contentful-components.link-list.linkColumn.${
                  isCollapsed ? 'view-more' : 'view-less'
                }`,
              )}
            </Button>
          </a>
        )}
      </div>
    </>
  );
};
