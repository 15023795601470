import { useQuery } from 'react-query';

import { fetchPage as proxyFetchPage } from '@services/contentful';
import useLang from '@utils/hooks/use-lang';

import { STALE_TIME } from '../constants/staleTime';
import { IClubContentPage } from '../contentful/types/IClubContentPage';
import { contentPageKeys } from '../react-query-keys/content-page';

export const useContentPage = (slug: string, isPreview = false) => {
  const { lang } = useLang();

  const fetchPage = async () => {
    try {
      return (await proxyFetchPage(slug, lang, isPreview)) as IClubContentPage;
    } catch (error) {
      // appsignal.sendError(error as Error);
      throw error;
    }
  };

  return useQuery<IClubContentPage, Error>(
    contentPageKeys.detailBySlug(slug),
    async () => await fetchPage(),
    {
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME * 1.5,
    },
  );
};
