import { isCenterOfNetherlands } from 'src/features/clubs-search/utils/is-center-of-netherlands';
import { GeolocationClient } from './client';
import { IGeoLocationApiResponse } from './types';
import mapLocation from './utils/map-location';

export async function getLocationByAddress(address: string) {
  const { data } = await GeolocationClient.get<IGeoLocationApiResponse>('', {
    params: {
      address: address,
      components: 'country:nl',
      key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      sensor: false,
    },
  });
  const location =
    data.status === 'OK' && data.results.length
      ? mapLocation(data.results[0])
      : null;

  // Workaround for Google Geolocation API returning the center of the Netherlands
  // when it can't find a location.
  return location && !isCenterOfNetherlands(location) ? location : null;
}
