import { Small } from 'src/features/shared/components/typography';
import styles from '../OpeningHoursTable.module.scss';

interface IExceptionProps {
  day: string;
  hours: string;
  isClosed: boolean;
  closedText: string;
}

export const Exception = ({
  day,
  hours,
  isClosed,
  closedText,
}: IExceptionProps) => (
  <li className={styles.item}>
    <Small className={styles.capitalize}>{day}</Small>
    <Small>{isClosed ? closedText : hours}</Small>
  </li>
);
