import { ClubWithCmsData } from 'src/features/pg-funnel/utils/funnel';
import { Club } from 'src/features/shared/types/Club';
import { matchTextWithRegex } from 'src/utils/regex-sort';

const MATCHING_THRESHOLD = 0.5;

export const getFilteredClubs = <T extends Club.Electrolyte | ClubWithCmsData>(
  clubs: T[],
  value: string,
): T[] => {
  const regex = new RegExp(
    '\\b(?:\\w*?' + value.replace(/ /g, '\\s*?|\\w*?') + '\\w*?)\\b',
    'gi',
  );
  const nameMatches = (name: string) =>
    matchTextWithRegex(name, regex) > MATCHING_THRESHOLD;
  const postalCodeMatches = (postalCode: string) =>
    matchTextWithRegex(postalCode, regex) > MATCHING_THRESHOLD;

  return clubs.filter(
    ({ name, postalCode }) =>
      nameMatches(name) || (postalCode && postalCodeMatches(postalCode)),
  );
};
