import theme from '@config/theme';
import { useIntl } from '@domains/i18n';
import { ACTIVE_FUNNELS } from '@features/pg-funnel/config/active-funnels';
import useFunnelConfig from '@features/pg-funnel/hooks/use-funnel-config';
import { Button } from '@features/shared/components/button';
import { ButtonType } from '@features/shared/types/enums/ButtonType';
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant';
import { useRouter } from 'next/router';

interface Props {
  clubId?: number;
  isDayPass?: true;
  isFullWidth?: boolean;
  isSecondary?: boolean;
  dataTestId?: string;
  onClick?: () => void;
}

export const JoinButton = ({
  clubId,
  isDayPass,
  isFullWidth,
  isSecondary,
  dataTestId,
  onClick,
}: Props) => {
  const { formatMessage } = useIntl();
  const { config } = useFunnelConfig();
  const { query } = useRouter();

  const isFunnel = config.steps.length > 0;
  const defaultCheckoutPath = ACTIVE_FUNNELS.sportcity.DEFAULT || 'default';
  const dayPassPath = ACTIVE_FUNNELS.sportcity.DAY_PASS;
  const slug = isDayPass
    ? dayPassPath
    : isFunnel
    ? query.slug
    : defaultCheckoutPath;
  const basePath = theme.checkout.path;
  const params = new URLSearchParams();
  if (clubId) {
    params.set('clubId', clubId.toString());
  }

  const queryPath = params.toString();
  const href = `${isDayPass ? '/checkout' : basePath}/${slug}?${queryPath}`;

  const getButtonVariant = () => {
    if (isDayPass) {
      return ButtonVariant.Inverted;
    }

    if (isSecondary) {
      return ButtonVariant.Secondary;
    }

    return ButtonVariant.Primary;
  };

  return (
    <Button
      type={ButtonType.Button}
      isFullWidth={isFullWidth}
      variant={getButtonVariant()}
      href={href}
      openInNewTab={isDayPass}
      isHtmlTag // NOTE: remove this after replacing getInitialProps from funnel slugs
      dataTestId={dataTestId}
      onClick={onClick}
    >
      {isDayPass
        ? formatMessage('day-pass.request')
        : isFunnel
        ? formatMessage('checkout.select-club.club-list.cta')
        : formatMessage('join')}
    </Button>
  );
};
