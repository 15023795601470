import { Button } from '@features/shared/components/button';
import { ButtonType } from '@features/shared/types/enums/ButtonType';
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant';

import styles from './ImageBanner.module.scss';

export interface IImageBannerProps {
  imageUrl: string;
  buttonLabel?: string;
  onClickButton?: () => void;
  buttonVariant?: ButtonVariant;
}

export const ImageBanner = ({
  imageUrl,
  buttonLabel,
  onClickButton,
  buttonVariant,
}: IImageBannerProps) => (
  <div className={styles.container}>
  
    <img src={imageUrl} alt="Image Banner" loading="lazy" />
    {onClickButton && buttonLabel && (
      <Button
        type={ButtonType.Button}
        variant={buttonVariant}
        onClick={onClickButton}
      >
        {buttonLabel}
      </Button>
    )}
  </div>
);


