import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Image as IImage } from '@features/pg-funnel/services/contentful/types';
import { Image } from '@features/shared/components/image';
import { IconButton } from '@features/shared/components/icon-button';
import Icons from 'features/shared/components/icons';
import { Direction } from '@features/shared/types/enums/Direction';
import { useIntl } from '@domains/i18n';
import { useDragToScroll } from '@features/shared/hooks/use-drag-to-scroll';

import styles from './Gallery.module.scss';

export interface IGalleryProps {
  items: IImage[];
  isMobile: boolean;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
}

export const Gallery = ({
  items,
  isMobile,
  onNextClick,
  onPreviousClick,
}: IGalleryProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  const [showLeftArrow, setShowLeftArrow] = useState(true);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const { handleMouseDown, handleMouseUp, handleMouseMove } =
    useDragToScroll(ref);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (ref.current) {
        const { scrollLeft, scrollWidth, clientWidth } = ref.current;
        setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        setShowLeftArrow(scrollLeft > 0);
      }
    };

    if (ref.current) {
      ref.current.addEventListener('scroll', checkScrollPosition);
      return () => {
        if (ref.current) {
          ref.current.removeEventListener('scroll', checkScrollPosition);
        }
      };
    }
  }, []);
  const handleScroll = (direction: Direction) => {
    if (ref.current && window) {
      ref.current.scrollBy?.({
        left:
          direction === Direction.Right
            ? window.innerWidth
            : -window.innerWidth,
        behavior: 'smooth',
      });
    }
  };

  const handlePreviousClick = () => {
    onPreviousClick?.();
    handleScroll(Direction.Left);
  };

  const handNextClick = () => {
    onNextClick?.();
    handleScroll(Direction.Right);
  };

  return (
    <>
      {isMobile ? (
        <div className={classNames(styles.gallery, styles.mobile)}>
          {items.map((item) => (
            <div key={item.src} className={styles.mobileItem}>
              <Image image={item} />
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className={classNames(styles.button, styles.left)}>
            <IconButton
              icon={<Icons.ArrowLeft />}
              onClick={handlePreviousClick}
              accessibleLabel={formatMessage('club-detail.gallery.left')}
              className={classNames({ [styles.hide]: !showLeftArrow })}
            />
          </div>

          <div className={classNames(styles.button, styles.right)}>
            <IconButton
              icon={<Icons.ArrowRight />}
              onClick={handNextClick}
              accessibleLabel={formatMessage('club-detail.gallery.right')}
              className={classNames({ [styles.hide]: !showRightArrow })}
            />
          </div>

          <div
            className={classNames(styles.gallery, styles.desktop)}
            ref={ref}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {items.map((item, index) =>
              index % 3 === 0 ? (
                <Fragment key={item.src}>
                  <div className={styles.galleryColumn}>
                    <Image image={item} className={styles.nonInteractive} />
                  </div>

                  {items[index + 1] || items[index + 2] ? (
                    <div className={styles.galleryColumn}>
                      {items[index + 1] ? (
                        <Image
                          image={items[index + 1]}
                          className={styles.nonInteractive}
                        />
                      ) : null}
                      {items[index + 2] ? (
                        <Image
                          image={items[index + 2]}
                          className={styles.nonInteractive}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </Fragment>
              ) : null,
            )}
          </div>
        </>
      )}
    </>
  );
};
