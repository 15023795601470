const CENTER_OF_NETHERLANDS = { lat: 52.132633, lng: 5.291265999999999 };

export function isCenterOfNetherlands(geolocation: {
  latitude: number;
  longitude: number;
}) {
  return (
    geolocation.latitude === CENTER_OF_NETHERLANDS.lat &&
    geolocation.longitude === CENTER_OF_NETHERLANDS.lng
  );
}
