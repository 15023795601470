import NextLink from 'next/link';
import theme from '@config/theme';
import { useClubs } from '@domains/clubs/hooks';
import { useIntl } from '@domains/i18n';
import { Club } from '@features/pg-funnel/services/perfect-gym/types';
import * as Carousel from '@features/shared/components/carousel';
import { ClubNearbyCard } from '@features/shared/components/clubs-nearby/club-nearby-card';
import { DescriptionTypography } from '@features/shared/components/description-typography';
import { Layout } from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout/section';
import { IClubWithGeoDistance } from '@features/shared/types/IClubWithGeoDistance';
import { IGeolocation } from '@models/IGeolocation';
import { addDistanceToClubs } from '@utils/add-distance-to-clubs';
import { ClubPageSectionHeading } from 'src/features/shared/components/typography';
import { GA4_EVENTS, pushGA4Event } from '../../utils/ga4';
import styles from './index.module.scss';

interface IClubsNearbyView {
  geoLocation: IGeolocation;
  clubName: string;
}

const distanceSort = (clubs: IClubWithGeoDistance[]) =>
  clubs.sort((a, b) => (a.geoDistance < b.geoDistance ? -1 : 1));

const settings = {
  slidesToShow: 3.7,
  responsive: [
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 3.7,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3.2,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 3.2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        centerPadding: '16px',
        centerMode: true,
      },
    },
  ],
};

export function ClubsNearbyView({ geoLocation, clubName }: IClubsNearbyView) {
  const [clubs] = useClubs();
  const { formatMessage } = useIntl();
  const isSportCity = theme.name === 'sportcity';

  const description = isSportCity
    ? formatMessage('club-detail.clubs-nearby.subtitle', {
        clubName,
        clubsCount: Object.keys(clubs).length,
      })
    : formatMessage('club-detail.clubs-nearby.description', {
        companyName: theme.brand,
      });

  const clubsArray: Club[] = Object.values(clubs);
  const clubsWithGeodistance = addDistanceToClubs<IClubWithGeoDistance>(
    clubsArray,
    geoLocation,
  );
  const nearbyClubs = distanceSort(clubsWithGeodistance).slice(1, 6);

  if (!nearbyClubs) return null;

  const items = nearbyClubs.map((club) => {
    return {
      ...club,
      image: club.cms?.clubHeaderMedia,
      title: club.name,
      description: `${club.address}, ${club.city}`,
      distance: `${Math.round(club.geoDistance / 1000)} km`,
      link:
        club.cms && club.cms.slug
          ? {
              href: `/${club.cms.slug}`,
            }
          : {},
    };
  });

  if (!items.length) return null;

  return (
    <Section>
      <Layout.Container>
        <Layout.Row>
          <Layout.Col columns="sm:10 md:8">
            <ClubPageSectionHeading>
              {formatMessage('club-detail.clubs-nearby.title')}
            </ClubPageSectionHeading>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row>
          <Layout.Col columns="xs:12 md:6 lg:5">
            <DescriptionTypography>
              <p>{description}</p>
            </DescriptionTypography>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row>
          <Layout.Col columns="xs:12">
            <Carousel.Root
              bottomButtons
              settings={settings}
              onNextClick={() => {
                pushGA4Event(GA4_EVENTS.trackEvent, {
                  event_name: 'show_more_clubs',
                });
              }}
            >
              {items.map((item) => (
                <Carousel.Item
                  key={item.description}
                  className={styles.carouselItem}
                >
                  {item.link.href && (
                    <NextLink
                      href={item.link.href}
                      onClick={() => {
                        pushGA4Event(GA4_EVENTS.trackEvent, {
                          event_name: 'click_club_nearby',
                        });
                      }}
                    >
                      <ClubNearbyCard
                        image={item.cms.clubHeaderMedia}
                        title={item.title}
                        description={item.description}
                        distance={item.distance}
                      />
                    </NextLink>
                  )}
                </Carousel.Item>
              ))}
            </Carousel.Root>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
}
