import { getAnchorTag } from '../../../../utils/use-anchor-links-tags'
import styles from '../index.module.scss'
interface IProps {
  title: string | null;
}

export function Anchor ({ title }: IProps) {
  if (!title) return null

  const { id } = getAnchorTag(title)

  return (
    <div className={styles.anchor} id={id} />
  )
}

