import {
  Body,
  FontColor,
  FontWeight,
} from 'src/features/shared/components/typography';
import styles from '../OpeningHoursTable.module.scss';

interface IItemProps {
  day: string;
  hours: string;
  isToday: boolean;
  isClosed: boolean;
  closedText: string;
}

export const Item = ({
  day,
  hours,
  isToday,
  isClosed,
  closedText,
}: IItemProps) => (
  <li className={styles.item} key={day}>
    <Body
      fontWeight={isToday && FontWeight.Bold}
      color={isToday ? FontColor.Neutral01 : FontColor.Neutral02}
    >
      {day}
    </Body>
    <Body fontWeight={isToday && FontWeight.Bold}>
      {isClosed ? closedText : hours}
    </Body>
  </li>
);
