import { useIntl } from 'src/domains/i18n';
import {
  OpeningHoursException,
  OpeningHoursItem,
} from 'src/features/checkout/types/checkout';
import { Body, FontWeight } from '../typography';
import styles from './OpeningHoursTable.module.scss';
import { Exception } from './exception';
import { Item } from './item';

interface OpeningHoursTableProps {
  openingHours?: OpeningHoursItem[];
  openingHoursExceptions?: OpeningHoursException[];
}

const DAY_MAP = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export function OpeningHoursTable({
  openingHours,
  openingHoursExceptions,
}: OpeningHoursTableProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <ul className={styles.openingHoursList}>
        {openingHours?.map((item) => (
          <Item
            key={item.day}
            isClosed={item.isClosed}
            hours={item.hours}
            day={formatMessage(`general.days.${item.day}`)}
            closedText={formatMessage('club-detail.opening-hours.closed')}
            isToday={DAY_MAP[new Date().getDay() - 1] === item.day}
          />
        ))}
      </ul>

      {openingHoursExceptions?.length ? (
        <div className={styles.innerContainer}>
          <Body fontWeight={FontWeight.Bold}>
            {formatMessage('club-detail.opening-hours.exception-title')}
          </Body>
          <ul className={styles.openingHoursExceptionsList}>
            {openingHoursExceptions.map((item) => (
              <Exception
                key={item.day}
                day={item.day}
                hours={item.hours}
                isClosed={item.isClosed}
                closedText={formatMessage('club-detail.opening-hours.closed')}
              />
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}
