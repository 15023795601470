import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Body } from '../components/typography';
import {RichText} from 'src/features/shared/components/rich-text';
import { INLINES } from '@contentful/rich-text-types';
import { Link } from '../components/link';
import { EntryFields } from 'contentful';
import { isDocumentNode } from '../contentful/utils/isDocumentNode';
import { LinkEntries } from 'src/features/pg-funnel/hooks/use-funnel-config/types';
import { isRichText } from '../contentful/utils/isRichText';

export function useRichTextRenderer(lang?: string, openLinksInNewTab = false) {
  const options: Options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const { file, title: fileTitle } = node.data.target.fields;
        let title = fileTitle;
        if (node.content[0]?.nodeType === 'text') {
          title = node.content[0].value;
        }
        return (
          <Link href={file.url} isHtmlTag openInNewTab>
            {title}
          </Link>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const { title: entryTitle, slug } = node.data.target.fields;
        const { sys } = node.data.target;

        let title = entryTitle;

        if (node.content[0]?.nodeType === 'text') {
          title = node.content[0].value;
        }
        const isFunnel = sys?.contentType.sys.id === 'funnel';
        const href = isFunnel ? `/funnel/${slug}` : `/${slug}`;

        return (
          <Link href={href} openInNewTab={openLinksInNewTab}>
            {title}
          </Link>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        const { uri } = node.data;
        let title;
        if (node.content[0].nodeType === 'text') {
          title = node.content[0].value;
        }
        return <Link href={uri}>{title}</Link>;
      },
    },
  };

  function renderOptions(links: LinkEntries): Options {
    // create an entry map
    const entryMap = new Map();

    // loop through the inline linked entries and add them to the map
    for (const entry of links.entries.hyperlink) {
      if (entry) {
        entryMap.set(entry.sys.id, entry);
      }
    }

    return {
      renderNode: {
        [INLINES.ENTRY_HYPERLINK]: (node) => {
          // find the entry in the entryMap by ID
          const entry = entryMap.get(node.data.target.sys.id);

          // render the entries as needed
          if (entry && entry.__typename === 'ContentPage') {
            let title = entry.title;

            if (node.content[0]?.nodeType === 'text') {
              title = node.content[0].value;
            }

            return openLinksInNewTab ? (
              <Link href={`/${entry.slug}`} openInNewTab>
                {title}
              </Link>
            ) : (
              <Link href={`/${entry.slug}`}>{title}</Link>
            );
          }
        },
      },
    };
  }

  const renderRichText = (
    richText: EntryFields.RichText,
    links?: LinkEntries,
  ) => {
    if (!isDocumentNode(richText)) {
      console.error('Invalid rich text data');
      return null;
    }

    if (richText && links) {
      return documentToReactComponents(richText, renderOptions(links));
    }

    return documentToReactComponents(richText, options);
  };

  const renderSectionDescription = (
    description: string | EntryFields.RichText,
    className?: string,
  ) => {
    if (!isRichText(description)) {
      return (
        <Body component="div" className={className}>
          {description}
        </Body>
      );
    }
    return (
      <RichText className={className}>{renderRichText(description)}</RichText>
    );
  };

  return {
    renderRichText,
    renderSectionDescription,
  };
}
