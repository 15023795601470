import { ClubWithCmsData } from 'src/features/pg-funnel/utils/funnel';
import { Club } from 'src/features/shared/types/Club';

export function removeDuplicateClubs(
  clubs: ClubWithCmsData[] | Club.Electrolyte[],
) {
  return clubs.filter((club, index, self) => {
    return index === self.findIndex((c) => c.pgClubId === club.pgClubId);
  });
}
