import { assertIsString } from 'src/utils/assertions/assertIsString';

export function matchTextWithRegex(text: string, regex: RegExp) {
  const match = text.match(regex);
  if (!match) return 0;
  return (match.join('').length / text.length) * 100;
}

export function regexSort<T>(list: T[], term: string, key: keyof T) {
  const regex = new RegExp(
    '\\b(?:\\w*?' + term.replace(/ /g, '\\s*?|\\w*?') + '\\w*?)\\b',
    'gi',
  );

  return list.sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];
    assertIsString(aValue);
    assertIsString(bValue);

    const matchA = matchTextWithRegex(aValue, regex);
    const matchB = matchTextWithRegex(bValue, regex);

    return matchB - matchA;
  });
}
