import Icons from 'features/shared/components/icons';
import { useIntl } from '@domains/i18n';
import { Layout } from '@features/shared/components/layout';
import { Section } from 'src/features/shared/components/layout';
import { Link } from '@features/shared/components/link';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ArrowButton } from 'src/features/shared/components/arrow-button';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import styles from './index.module.scss';
import { Body, FontWeight } from 'src/features/shared/components/typography';

interface IProps {
  tags: {
    label: string;
    id: string;
  }[];
  isPanel?: boolean;
}

export const AnchorLinks = ({ tags, isPanel }: IProps) => {
  const { formatMessage } = useIntl();
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;
  const horizontalScrollRef = useRef<HTMLUListElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    onScroll();
  }, []);

  const onScrollRightClick = () => {
    horizontalScrollRef.current?.scrollBy({ left: 200, behavior: 'smooth' });
  };

  const onScrollLeftClick = () => {
    horizontalScrollRef.current?.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const onScroll = () => {
    const scrollContainer = horizontalScrollRef.current;
    if (!scrollContainer || isMobile) return;

    const isOverflowing =
      scrollContainer.scrollWidth > scrollContainer.clientWidth;

    if (!isOverflowing) {
      setShowLeftArrow(false);
      setShowRightArrow(false);
    } else {
      setShowLeftArrow(scrollContainer?.scrollLeft > 50);
      setShowRightArrow(
        scrollContainer.scrollLeft + scrollContainer.clientWidth <
          scrollContainer.scrollWidth - 32,
      );
    }
  };

  return (
    <Section
      className={classNames(styles.section, isPanel && styles.isPanel)}
      forceMobileView={isPanel}
    >
      <Layout.Container forceMobileView={isPanel}>
        <Layout.Row>
          <Layout.Col className={styles.col}>
            <div className={classNames(styles.arrowButtonWrapper, styles.left)}>
              <ArrowButton
                isPrev
                isHidden={isMobile || !showLeftArrow}
                className={styles.arrowButton}
                onClick={onScrollLeftClick}
              />
            </div>
            <ul
              onScroll={onScroll}
              className={styles.anchorList}
              ref={horizontalScrollRef}
            >
              <li className={classNames(styles.anchorItem, styles.anchorLabel)}>
                <Icons.ArrowRight className={styles.icon} />
                <Body fontWeight={FontWeight.Bold}>
                  {formatMessage('club-detail.anchor-links.title')}
                </Body>
              </li>
              {tags.map((tag, index) => (
                <li
                  key={tag.id}
                  className={classNames(
                    styles.anchorItem,
                    index === tags.length - 1 && styles.lastItem,
                  )}
                >
                  <Link href={`#${tag.id}`} isHtmlTag isSmall>
                    {tag.label}
                  </Link>
                </li>
              ))}
            </ul>
            <div
              className={classNames(styles.arrowButtonWrapper, styles.right)}
            >
              <ArrowButton
                onClick={onScrollRightClick}
                isHidden={isMobile || !showRightArrow}
              />
            </div>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
};
