import { IMenu } from '@features/shared/contentful/types/IMenu';
import { Layout } from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout';
import { LinkColumn } from './link-column';
import { ImageBanner } from './image-banner';
import { IImageBanner } from '@features/shared/contentful/types/IImageBanner';
import { useRouter } from 'next/router';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import styles from './index.module.scss';
import { H2 } from 'src/features/shared/components/typography';

export interface ILinkListProps {
  title: string;
  menuItems: IMenu[];
  imageBanner?: IImageBanner;
}

export const LinkList = ({ title, menuItems, imageBanner }: ILinkListProps) => {
  const router = useRouter();
  const href = imageBanner?.cta?.href;

  const isImageBanner = typeof imageBanner?.mediaSrc === 'string';

  const onClick = () => {
    href && router.push(href);
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: 'click_cta_linklist' });
  };

  return (
    <Section>
      <Layout.Container>
        <Layout.Row>
          <Layout.Col>
            <H2 className={styles.title}>{title}</H2>

            <Layout.Row>
              <Layout.Col columns={isImageBanner ? 'xs:12 sm:6 md:8 lg:9' : ''}>
                {menuItems.map((item) => (
                  <LinkColumn
                    key={item.id}
                    links={item.items}
                    subtitle={item.subtitle}
                    isImageBanner={isImageBanner}
                  />
                ))}
              </Layout.Col>

              <Layout.Col columns="xs:12 sm:6 md:4 lg:3">
                {isImageBanner ? (
                  <ImageBanner
                    imageUrl={imageBanner.mediaSrc || ''}
                    onClickButton={onClick}
                    buttonLabel={imageBanner.cta?.label}
                    buttonVariant={imageBanner.buttonVariant}
                  />
                ) : null}
              </Layout.Col>
            </Layout.Row>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
};
