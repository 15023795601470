import { useIntl } from '@domains/i18n';
import { JoinButton } from '@features/club-page/components/join-button';
import { breakClubTitleName } from '@features/club-page/utils/breakClubTitleName';
import getClubIsOpen from '@features/club-page/utils/get-club-is-open';
import { Image as IImage } from '@features/pg-funnel/services/contentful/types';
import { Button } from '@features/shared/components/button';
import { Image } from '@features/shared/components/image';
import { Layout } from '@features/shared/components/layout';
import { Rating } from '@features/shared/components/rating';
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant';
import { GA4_EVENTS, pushGA4Event } from '@features/shared/utils/ga4';
import { IAsset } from '@models/IAsset';
import classNames from 'classnames';
import Icons from 'features/shared/components/icons';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Section } from 'src/features/shared/components/layout';
import {
  ClubPageHeading,
  FontColor,
  FontWeight,
  Large,
} from 'src/features/shared/components/typography';
import { Club } from 'src/features/shared/types/Club';
import styles from './ClubPageHeader.module.scss';

interface IClubPageHeaderProps {
  club: Club.Details;
  media: IAsset | IImage;
  isMobile: boolean;
  isPanel: boolean;
  onClickGallery: null | ((e: React.MouseEvent) => void);
}

export const ClubPageHeader = ({
  club,
  media,
  isMobile,
  isPanel,
  onClickGallery,
}: IClubPageHeaderProps) => {
  const { formatMessage } = useIntl();
  const { isOpen, openFrom, openUntil } = getClubIsOpen(club);
  const router = useRouter();
  const translations = useMemo(
    () =>
      isOpen
        ? {
            isOpen: formatMessage('club-detail.opening-hours.open'),
            fromUntil: formatMessage('club-detail.opening-hours.open-until', {
              hour: openUntil,
            }),
          }
        : openFrom
        ? {
            // if we know when the club will open next, we show that
            isOpen: formatMessage('club-detail.opening-hours.open-next', {
              day: formatMessage(`general.days.${openFrom?.day}`),
            }),
            fromUntil: formatMessage('club-detail.opening-hours.open-from', {
              hour: openFrom?.hour,
            }),
          }
        : {
            // if we don't know when the club is open next, we only show that it's closed
            isOpen: formatMessage('club-detail.opening-hours.closed'),
            fromUntil: undefined,
          },
    [isOpen, openFrom, openUntil, formatMessage],
  );

  const getCheckoutUrl = () => {
    router.query.clubDetails && delete router.query.clubDetails;
    return {
      pathname: router.pathname,
      query: { ...router.query, clubId: club.pgClubId },
    };
  };

  return (
    <Section forceMobileView={isPanel}>
      <Layout.Container forceMobileView={isPanel}>
        <Layout.Row
          className={classNames(styles.row, { [styles.isPanel]: isPanel })}
        >
          <Layout.Col columns={isPanel ? 'sm:12' : 'sm:6 lg:6'}>
            <div
              className={classNames(
                styles.imageContainer,
                isPanel && styles.isPanel,
                { [styles.isClickable]: onClickGallery },
              )}
              onClick={onClickGallery ?? undefined}
            >
              {media ? (
                <Image
                  image={media}
                  isClickable={onClickGallery !== null}
                  className={styles.mainImage}
                />
              ) : null}
              <div className={styles.imageButtons}>
                {onClickGallery ? (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickGallery(e);
                    }}
                    variant={ButtonVariant.Club}
                    icon={<Icons.Eye />}
                  >
                    {formatMessage('club-detail.club-header.all-photos')}
                  </Button>
                ) : null}
              </div>
            </div>
          </Layout.Col>
          <Layout.Col
            className={classNames(
              styles.textContainer,
              isPanel && styles.isPanel,
            )}
            columns={isPanel ? 'sm:12' : 'sm:6 lg:6'}
          >
            <ClubPageHeading>
              <p
                dangerouslySetInnerHTML={{
                  __html: breakClubTitleName(club.name),
                }}
              />
            </ClubPageHeading>
            <div className={styles.infoButtonsContainer}>
              <div className={styles.infoContainer}>
                <div className={styles.column}>
                  <Large>{club.address}</Large>
                  <Large>
                    {club.postalCode} {club.city}
                  </Large>
                </div>
                <div className={styles.openingHours}>
                  <Large
                    fontWeight={FontWeight.Bold}
                    color={FontColor.Neutral02}
                    hasSuccess={isOpen}
                  >
                    {translations.isOpen}
                  </Large>
                  <Large color={FontColor.Neutral02}>
                    {translations.fromUntil}
                  </Large>
                </div>

                {isPanel && (
                  <div className={styles.selectClubButton}>
                    <Button isFullWidth href={getCheckoutUrl()}>
                      {formatMessage(
                        'checkout.select-club.sidepanel.choose-club',
                      )}
                    </Button>
                  </div>
                )}
              </div>

              <div className={styles.buttonsContainer}>
                {!isPanel && (
                  <>
                    <JoinButton
                      clubId={club.pgClubId}
                      isFullWidth={isMobile}
                      onClick={() =>
                        pushGA4Event(GA4_EVENTS.trackEvent, {
                          event_name: 'click_cta_club_primary',
                        })
                      }
                      dataTestId={
                        isPanel ? 'club-side-panel_cta_1' : 'club-page_cta_1'
                      }
                    />
                    <JoinButton
                      clubId={club.pgClubId}
                      isDayPass
                      onClick={() =>
                        pushGA4Event(GA4_EVENTS.trackEvent, {
                          event_name: 'click_cta_club_secondary',
                        })
                      }
                      dataTestId="club-page_cta_2"
                      isFullWidth={isMobile}
                    />
                  </>
                )}
              </div>
            </div>
            <Rating isPanel={isPanel} />
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
};

export default ClubPageHeader;
