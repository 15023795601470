import Icons from 'features/shared/components/icons';
import { Note, Small } from 'src/features/shared/components/typography';
import styles from './index.module.scss';
import classNames from 'classnames';

export enum DescriptionColor {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface InputGroupProps {
  label?: string;
  id?: string;
  error?: string | boolean;
  description?: string;
  descriptionColor?: DescriptionColor;
  isValid?: boolean;
  hasVisualIndicator?: boolean;
  children: React.ReactNode;
  className?: string;
}

export function InputGroup({
  label,
  id,
  error,
  description,
  descriptionColor = DescriptionColor.ERROR,
  children,
  hasVisualIndicator,
  className,
  isValid = false,
}: InputGroupProps) {
  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <Note hasError={Boolean(error)} className={styles.label}>
          <label htmlFor={id}>{label}</label>
          {error && <Icons.ErrorExclamation className={styles.indicator} />}
          {hasVisualIndicator && isValid && (
            <Icons.CheckMark className={styles.indicator} />
          )}
        </Note>
      )}
      {children}
      {description && (
        <Small
          hasSuccess={descriptionColor === DescriptionColor.SUCCESS}
          hasError={Boolean(error)}
          className={classNames(styles.description, {
            [styles.success]: isValid,
          })}
        >
          {description}
        </Small>
      )}
    </div>
  );
}
