import styles from './CurlyLine.module.scss'

interface ISvgCurlyLineProps {
  className: string;
}

/**
 * @NOTE - for now created here as importing an SVG file directly doesn't work
 */
const SvgCurlyLine = ({ className }: ISvgCurlyLineProps) => (
  <svg viewBox="0 0 1440 386" fill="none" className={className} preserveAspectRatio="xMaxYMin">
    <path
      d="M2140.17 377.984c-1504.5-749-1205.5-113.582-2282.5-191.082"
      stroke="#F4B52C"
      strokeWidth={16}
    />
  </svg>
)

export const CurlyLine = () => {
  return (
    <div className={styles.container}>
      <div className={styles.viewBox}>
        <SvgCurlyLine className={styles.curlyLine} />
      </div>
    </div>
  )
}
