import { UspBlock } from '@features/pg-funnel/components/content-items/funnel-usp-block';
import { GqlStep, Image } from '@features/pg-funnel/services/contentful/types';
import { IAttributeOptionFields } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import { FetchedAttribute } from '@features/pg-funnel/services/contentful/utils/map-attribute-choice-content';
import { Attribute } from '@features/pg-funnel/services/contentful/utils/map-attributes';
import { Discount } from '@features/pg-funnel/services/perfect-gym/types';
import { AttributeValue } from '@features/pg-funnel/types';
import { EntryFields } from 'contentful';
import { Club } from '../../services/proxy/types/IClubs';

export interface GqlImage {
  title: string;
  description?: string;
  url: string;
  height: number;
  width: number;
}

export interface Tooltip {
  title: string;
  description: string;
  addLineBreaks?: boolean;
}

export interface Usp {
  id?: string;
  description: EntryFields.RichText | string;
  tooltip?: Tooltip;
  isHighlighted?: boolean;
  isGreyedOut?: boolean;
  isTag?: boolean;
  icon?: 'Plus' | 'Checkmark' | 'Cross' | 'Arrow';
}

export interface Section {
  title: string;
  subtitle?: EntryFields.RichText;
}

export interface AttributeOption {
  id: string;
  title: string;
  description?: EntryFields.RichText;
  badge?: string;
  badgeSecondary?: string;
  usps?: Usp[];
  oneClubUspsSc87?: Usp[];
  plusClubUspsSc25?: Usp[];
  oneClubUspsSc25?: Usp[];
  isChecked?: boolean;
  price?: number;
  priceDescription?: string;
  insertClubUsp: boolean;
  preselect: boolean;
}

export type AttributeOptionsById = {
  [key: string]: AttributeOption;
};

export enum AttributeId {
  COMMITMENT_PERIOD = 'commitmentPeriodId',
  PAYMENT_INTERVAL = 'paymentIntervalId',
  CONTRACT_TYPE = 'contractType',
}

export interface AttributeChoice extends Section {
  attributeId: AttributeId | string;
  isPairOptions: boolean;
  options?: AttributeOptionsById;
}

export interface AddOnOption {
  addOnId: string;
  title: string;
  image?: Image;
  detailHeaderImage?: Image;
  description?: EntryFields.RichText;
  badge?: string;
  badgeSecondary?: string;
  usps?: Usp[];
}

export interface AddOnsSection {
  options: AddOnOption[];
}

export interface ClubSearch {
  useMyLocationCopy: string;
  clubsFoundCopy: string;
}

export interface EditClub {
  yourClubCopy: string;
  editCopy: string;
}

export enum PersonalInfoFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  BIRTH_DATE = 'birthDate',
  GENDER = 'gender',
}

export enum AddressFields {
  STREET = 'street',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  HOUSE_NUMBER = 'houseNumber',
  HOUSE_NUMBER_ADDITION = 'houseNumberAddition',
}

export enum ContactFields {
  PHONE_NUMBER = 'phoneNumber',
  EMAIL_ADDRESS = 'emailAddress',
}

export enum StudentFields {
  STUDENT_NUMBER = 'studentNumber',
}

export enum StartDateFields {
  START_DATE = 'startDate',
}

export interface DatePicker extends Section {
  fields: {
    [key in StartDateFields]: {
      label: string;
      caption?: string;
    };
  };
}

export interface AddressForm extends Section {
  fields: {
    [key in AddressFields]: {
      label: string;
      caption?: string;
    };
  };
}

export interface ContactForm extends Section {
  fields: {
    [key in ContactFields]: {
      label: string;
      caption?: string;
    };
  };
}

export interface StudentForm extends Section {
  fields: {
    [key in StudentFields]: {
      label: string;
      caption?: string;
    };
  };
}

export interface Addon {
  id: string;
  title: string;
  subtitle: string;
  price: number;
  priceSuffix: string;
}

export interface AddonsChoice {
  addons: Addon[];
}

export interface FitForFriends extends Section {
  body: string;
  toggleButtonCopy: string;
  activateCodeButtonCopy: string;
}

export interface ContentPage {
  title: string;
  slug: string;
  sys: {
    id: string;
  };
}

export interface Summary extends Section {
  title: string;
  description: string;
  badge: string;
  infoBox: string;
  includeVoucherCode?: boolean;
  includeAccessCode?: boolean;
  voucherCodeDefaultOpened?: boolean;
  includeStartDate: boolean;
  acceptPaymentMethod: {
    body: EntryFields.RichText;
    links: LinkEntries;
  };
  acceptGeneralTerms: {
    body: EntryFields.RichText;
    links: LinkEntries;
  };
  selectedClub: Club;
}

// TODO: remove content types when migration of flexible sales funnel app is completed
export enum ContentTypes {
  FUNNEL_ATTRIBUTE_CHOICE = 'funnelAttributeChoice',
  FUNNEL_CLUB_SEARCH = 'funnelClubSearch',
  FUNNEL_CLIENT_INFO_FORM = 'funnelClientInfoForm',
  FUNNEL_SUMMARY = 'funnelSummary',
  CHECKOUT_USPS = 'checkoutUsps',
  FUNNEL_ADD_ONS_SECTION = 'funnelAddOnsSection',
}

export interface CreateContentItem<Type extends ContentTypes, Content> {
  type: Type;
  content: Content;
  form?: {
    // todo: specify
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validationSchema: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValues: any;
  };
}

export type AttributeChoiceItem = CreateContentItem<
  ContentTypes.FUNNEL_ATTRIBUTE_CHOICE,
  AttributeChoice | null
>; // for the AttributeChoice, the content is set in
export type AddOnsSectionItem = CreateContentItem<
  ContentTypes.FUNNEL_ADD_ONS_SECTION,
  AddOnsSection | null
>;
export type ClubSearchItem = CreateContentItem<
  ContentTypes.FUNNEL_CLUB_SEARCH,
  ClubSearch
>;
export type PersonalInfoFormItem = CreateContentItem<
  ContentTypes.FUNNEL_CLIENT_INFO_FORM,
  null
>;
export type SummaryItem = CreateContentItem<
  ContentTypes.FUNNEL_SUMMARY,
  Summary
>;
export type UspBlockItem = CreateContentItem<
  ContentTypes.CHECKOUT_USPS,
  UspBlock
>;

export type ContentItem =
  | AttributeChoiceItem
  | ClubSearchItem
  | PersonalInfoFormItem
  | SummaryItem
  | UspBlockItem
  | AddOnsSectionItem;

export interface Step {
  title: string;
  shortTitle?: string;
  pageType?: string | null;
  subtitle?: EntryFields.RichText;
  slug: string;
  contentItems: ContentItem[] | null;
  logo?: GqlImage;
  pagination: {
    next: {
      label: string;
    };
    prev: {
      label: string;
    };
  };
  footerText?: EntryFields.RichText;
}

export interface PaymentPlanAttributesById {
  [id: number]: {
    [key: string]: AttributeValue;
  };
}

export interface FunnelConfig {
  steps: Step[];
  discountIds: number[];
  isDefault: boolean;
  attributes: Attribute[];
  includeStartDate: boolean;
  metaDescription?: string;
  paymentPlanAttributesByPaymentPlanId: PaymentPlanAttributesById;
  /*
    This is needed because it's possible to have additional filter attributes that are not AttributeChoices
    For example: clubId
   */
  additionalFilterAttributeIds: {
    [key: string]: string | number | string[] | null;
  };
  includeAccessCode?: boolean;
  voucherCodeDefaultOpened?: boolean;
  clubCardDetails?: 'Side Panel' | 'USPs' | 'None';
  accessCodeDescription?: {
    json: EntryFields.RichText;
  };
}

export interface Option {
  id: string;
  name: string;
  content:
    | ({
        type: 'attributeOption';
      } & IAttributeOptionFields)
    | null;
  attributeId: string;
}

export interface AttributeEntry {
  id: string;
  title: string;
  description?: EntryFields.RichText;
}

export type CmsConfig = Pick<FunnelConfig, 'isDefault'> & {
  includeVoucherCode?: boolean;
  includeAccessCode?: boolean;
  voucherCodeDefaultOpened?: boolean;
  clubCardDetails?: 'Side Panel' | 'USPs' | 'None';
  pageType?: string;
  accessCodeDescription?: {
    json: EntryFields.RichText;
  };
  includeStartDate: boolean;
  metaDescription?: string;
  perfectGymPicker: {
    // todo: this type is in development and could be subject to change, please check if it needs to be updated
    discounts: {
      [id: string]: Discount;
    };
    attributes: FetchedAttribute[];
    paymentPlanAttributesByPaymentPlanId: PaymentPlanAttributesById; // todo: check type => clarify it's by paymentPlanId
  };
  funnelStepsCollection: {
    items: GqlStep[];
  };
};

export type LinkEntries = {
  entries: {
    hyperlink: ContentPage[];
  };
};
